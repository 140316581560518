export const topicTypes = {
  analytics_insights: "Analytics & Insights",
  billing_accounting: "Billing & Accounting",
  churn_management: "Churn Management",
  customer_acquisition: "Customer Acquisition",
  customer_experience: "Customer Experience",
  customer_retention: "Customer Retention",
  health_wellness: "Health & Wellness",
  payment_recovery: "Payment Recovery",
  publishing_elearning: "Publishing & eLearning",
  recurring_billing: "Recurring Billing",
  recurring_payments: "Recurring Payments",
  saas: "SaaS",
  security_compliance: "Security & Compliance",
  sports: "Sports",
  subscription_billing: "Subscription Billing",
  subscription_business: "Subscription Business",
  subscription_intelligence: "Subscription Intelligence",
  subscription_management: "Subscription Management",
  subscription_payments: "Subscription Payments",  
}

export const contentTypes = {
  whitepapers: "Whitepapers",
  reports: "Reports",
  videos: "Videos",
  datasheets: "Datasheets",
  ebooks: "eBooks",
  infographics: "Infographics",
  guides: "Guides",
}

export const getSlug = (value) => {
  switch (value) {
    case "whitepapers":
      return "white-papers"
    case "reports":
      return "analyst-reports-reviews"
    default:
      return value
  }
}

export const getCTA = (value) => {
  switch (value) {
    case "whitepapers":
    case "reports":
    case "ebooks":
    case "guides":
      return "Read more"
    case "infographics":
      return "View"
    case "videos":
      return "Watch video"
    case "datasheets":
      return "Read datasheet"
  }
}
